<template>
    <div class="body">
        <div>
            <van-list
                    v-model="listLoading"
                    :finished="listLoadingFinished"
                    finished-text="没有更多了"
                    @load="onLoad"
            >
            <div class="booking-wrapper" v-for="(item, index) in listLoadingList" :key="index">
                <div class="booking-order-id">{{item.orderID}}</div>
                <div class="booking-info-wrapper">
                    <img class="booking-img" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item.businessInfo.trademark">
                    <div class="booking-name">{{item.productInfo.name}}</div>
                </div>
                <div class="booking-footer">
                    <p class="booking-time">{{item.create_time}}</p>
                    <a class="booking-button" :href="item.UUqrcodeURL">去查看</a>
                </div>
            </div>
            </van-list>
        </div>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import { globalConfig, listLoading } from '../../utils/mixin'
  import FloatNav from '../../components/common/FloatNav'

  export default {
    name: 'BookingList',
    components: { FloatNav },
    mixins: [globalConfig, listLoading],
    data () {
      return {}
    },
    methods: {
    },
    mounted () {
      this.listLoadingInit(this.apiUrl + 'mall/ticket/getTicketOrder', 'list', 1, {
        status: 1
      })
    }
  }
</script>

<style scoped>
    .body {
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        background-color:#eee;
    }
    .booking-wrapper{
        background-color: #FFFFff;
        border-radius: 0.2rem;
        width: 94vw;
        display: flex;
        flex-flow: column;
        margin: 2vw 3vw 5vw 3vw;
    }
    .booking-order-id{
        width: 86%;
        border-bottom: 0.01rem solid #eeeeee;
        margin: 0 4vw;
        font-size: 0.9rem;
        color: #666;
        padding: 1rem 0;
        letter-spacing: 0.1rem;
    }
    .booking-info-wrapper{
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        border-bottom: 0.01rem solid #eeeeee;
        background-color: #ffffff;
        padding:1rem 1rem 0.5rem 1rem;
        border-radius: 2vw;
    }
    .booking-img{
        width: 4rem;
        height: 4rem;
        border-radius: 0.3rem;
        border: 1px solid #efefef;
    }
    .booking-name{
        width: 80%;
        font-size: 3.6vw;
        color: #666;
    }
    .booking-footer{
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 0.2rem;
        padding: 1vw 4vw 3vw 4vw
    }
    .booking-time {
        font-size: 3.2vw;
        color: #898989;
    }
    .booking-button{
        border-radius: 4vw;
        border: 1px solid #ff9f1d;
        font-size: 3.4vw;
        color: #ff9f1d;
        padding: 0.5vw 4vw;
    }
</style>
